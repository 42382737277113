<template>
  <div class="dashboard_alarm_panel">
    <div class="div_alarm">
      <div
        style="
          height: 100%;
          width: 100%;
          display: inline-flex;
          justify-content: center;
          z-index: 1;
        "
      >
        <div
          style="height: 100%; width: 100%; display: inline-flex"
          v-if="objLength()"
          ref="popupElement"
        >
          <div
            style="
              height: 230%;
              width: 100%;
              background-image: url('resources/img/icons/dashboard/Rectangle 5.png');
              background-size: 100% 100%;
              background-repeat: no-repeat;
            "
          >
            <div style="position: relative; overflow: hidden; height: 35px">
              <div
                style="
                  position: absolute;
                  top: 40%;
                  left: 5%;
                  display: flex;
                  align-items: center;
                "
              >
                <img
                  style="
                    width: 1.5vw;
                    float: left;
                    margin-left: -1.5rem;
                    height: 20px;
                  "
                  src="resources/img/icons/dashboard/202.svg"
                />
                <span class="popup-title">{{ selectTunnelName }}</span>
              </div>
            </div>
            <div style="display: flex; width: 100%; height: 85%">
              <div
                id="eventModalVideoFrame"
                style="width: 80%; height: 100%; margin: 18px 10px 5px 21px"
              >
                <div class="iframebox">
                  <video
                    style="background-color: black"
                    id="alramVideo"
                    width="100%"
                    height="100%"
                    autoplay
                    muted
                    playsinline
                  ></video>
                </div>
              </div>
              <div style="height: 100%; width: 50%">
                <div
                  style="
                    height: 20%;
                    width: 100%;
                    text-align: center;
                    align-items: center;
                    justify-content: center;
                    margin-top: 4%;
                  "
                >
                  <div class="alarm_main_title">
                    <!-- {{ alarm_obj[0].link_code_nm }} {{ $t("label.L0087") }} -
                    {{ alarm_obj[0].d_event_type_nm }}{{ $t("label.L0099") }}<br>{{ alarm_obj[0].occur_time_string }} -->
                    {{ alarm_obj[0].link_code_nm }} {{ $t("label.L0087") }}
                    <br />{{ alarm_obj[0].occur_time_string }}
                  </div>
                  <!-- <div class="alarm_main_title_down font-custom">
                    {{ alarm_obj[0].occur_time_string }}
                  </div> -->
                </div>
                <div style="height: 7%"></div>
                <!-- 알람 리스트 -->
                <div
                  class="table-responsive scroll-test"
                  style="height: 53%; overflow-x: hidden"
                >
                  <table
                    class="table align-items-center dashboard_table_center"
                  >
                    <thead style="color: #f8f8fb">
                      <tr>
                        <th
                          scope="col"
                          class="font-custom"
                          style="
                            text-align: center;
                            border-style: solid;
                            border-width: 1px;
                            border-color: #999999;
                            line-height: 1rem;
                            padding: 0px !important;
                            background-color: rgba(44, 44, 44, 1);
                            vertical-align: middle;
                          "
                        >
                          {{ $t("label.L0069") }}
                        </th>
                        <th
                          scope="col"
                          class="font-custom"
                          style="
                            text-align: center;
                            border-style: solid;
                            border-width: 1px;
                            border-color: #999999;
                            line-height: 1rem;
                            padding: 5px !important;
                            background-color: rgba(44, 44, 44, 1);
                            vertical-align: middle;
                          "
                        >
                          {{ $t("label.L0048") }}
                        </th>
                        <th
                          scope="col"
                          class="font-custom"
                          style="
                            text-align: center;
                            border-style: solid;
                            border-width: 1px;
                            border-color: #999999;
                            line-height: 1rem;
                            padding: 5px !important;
                            background-color: rgba(44, 44, 44, 1);
                            vertical-align: middle;
                          "
                        >
                          {{ $t("label.L0045") }}
                        </th>
                      </tr>
                    </thead>
                    <tbody class="list">
                      <!-- <tr v-for="event in alarm_obj[0].objalarmList" v-bind:key="event.no"> -->
                      <tr
                        v-for="(event, index) in alarm_obj[0].objalarmList"
                        :key="index"
                      >
                        <td
                          class="font-custom"
                          style="
                            border-style: solid;
                            border-width: 1px;
                            border-color: #999999;
                            text-align: center;
                            color: #f8f8fb;
                            opacity: 1;
                            line-height: 1rem;
                            padding: 5px !important;
                            vertical-align: middle;
                          "
                        >
                          {{ event.camera_id }}
                        </td>
                        <td
                          class="font-custom"
                          style="
                            border-style: solid;
                            border-width: 1px;
                            border-color: #999999;
                            text-align: center;
                            color: #f8f8fb;
                            opacity: 1;
                            line-height: 1rem;
                            padding: 5px !important;
                            vertical-align: middle;
                          "
                        >
                          {{ event.d_event_type_nm }}
                        </td>
                        <td
                          class="font-custom"
                          style="
                            border-style: solid;
                            border-width: 1px;
                            border-color: #999999;
                            text-align: right;
                            color: #f8f8fb;
                            opacity: 1;
                            line-height: 1rem;
                            padding: 5px !important;
                            vertical-align: middle;
                          "
                        >
                          {{ event.position }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="alarm_button2">
                  <span
                    class="alarm_button_title"
                    v-if="alarm_obj.length > 1"
                    @click="nextData(alarm_obj[0].alarmTargetNo)"
                    >{{ $t("button.B0009") }} ( {{ alarm_obj.length }} )</span
                  >
                  <span
                    class="alarm_button_title"
                    v-else
                    @click="closebutton"
                    >{{ $t("button.B0001") }}</span
                  >
                </div>
              </div>
              <div style="width: 35px; display: flex"></div>
            </div>
            <!-- <div style="position: relative; overflow: hidden; height: 50px; transform: rotate(180deg);">
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <audio :src="sound_url" muted hidden id="audio"></audio>
  </div>
</template>

<script>
import EventBus from "@/event-bus";

export default {
  emits: ["openModal"],
  name: "Alarm",
  components: {},
  data: function () {
    return {
      alarm_obj: [],
      eventInfoList: [],
      alarmList: [],
      objalarmList: [],
      current_time: 0,
      file_url: "",
      file_name: "",
      sound_file_url: "",
      sound_file_name: "",
      alarmTimer: null,
      sync: false,
      scheduleList: [],
      scheduleTimer: null,
      equipList: [],
      tunnel_to_linkcode: { TDT: ["LNK-0450-CMT", "LNK-0450-MCT"] },
      selectTunnelCode: null,
      selectTunnelInfo: [],
      selectTunnelName: null,
      // tunnel_title: {
      //   "LNK-0450-CMT": this.$store.getters['settingConfig/getTarget']('gs').direction.up + " 방면 - ",
      //   "LNK-0450-MCT": this.$store.getters['settingConfig/getTarget']('gs').direction.down + " 방면 - ",
      // },
      event_type: {
        1: this.$t("label.L0027"),
        2: this.$t("label.L0022"),
        3: this.$t("label.L0023"),
        4: this.$t("label.L0025"),
        6: this.$t("label.L0024"),
        9: this.$t("label.L0026"),
        "01": this.$t("label.L0027"),
        "02": this.$t("label.L0022"),
        "03": this.$t("label.L0023"),
        "04": this.$t("label.L0025"),
        "06": this.$t("label.L0024"),
        "09": this.$t("label.L0026"),
        11: this.$t("label.L0091"),
        12: this.$t("label.L0092"),
        13: this.$t("label.L0093"),
        14: this.$t("label.L0094"),
      },
      event_type_image: {
        "01": "resources/img/icons/dashboard/stopping-car_event.svg",
        "02": "resources/img/icons/dashboard/pedestrian_event.svg",
        "03": "resources/img/icons/dashboard/reverse_event.svg",
        "04": "resources/img/icons/dashboard/event_circle.svg",
        "06": "resources/img/icons/dashboard/event_circle.svg",
        "09": "resources/img/icons/dashboard/event/bottleneck.svg",
        11: "resources/img/icons/dashboard/event/sound.svg",
        12: "resources/img/icons/dashboard/event/sound.svg",
        13: "resources/img/icons/dashboard/event/sound.svg",
        14: "resources/img/icons/dashboard/event/sound.svg",
      },
      sound_code: "192.168.0.1",
      sound_url: "",
      audioTag: null,
      // testObj:{
      // 	CameraID:"TDT-CAM-S-A00100",
      // 	DEventType:"12",
      // 	Distance:"0",
      // 	E_Latitude:"0",
      // 	E_Longitude:"0",
      // 	Elevation:"0",
      // 	LinkCode:"LNK-0450-CMT",
      // 	OccurTime:"20230623162828",
      // 	Position:"0.2",
      // 	RecImageUrl:"test.com",
      // 	RecVideoUrl:"tesc.ccc",
      // 	bound:"1",
      // 	no:888,
      // 	tech:"C",
      // 	test:"test"
      // }
      // 알람팝업창 데이터 고유 값없어서...
      alarmTargetNo: 0,
      player: null,
      alramalias: "",
      mediaServerInfo: {
        ip: null,
        port: null,
      },
      eventPopupTimer:
        this.$store.getters["verticalMenu/adminUser"].eventPopupTimer,
      autoChangeEventList: null,
      popupCancleEventList: null,
    };
  },
  filters: {
    toFixed: function (val, num) {
      return parseFloat(val).toFixed(num);
    },
  },
  methods: {
    objLength: function () {
      return this.alarm_obj.length > 0 ? true : false;
    },
    setIntervalT: function (no) {
      // no : alarmTargetNo
      this.sync = true;
      const eventPopupKeepYn =
        this.$store.getters["verticalMenu/eventPopupKeepYn"]; // 알람 팝업 지속 여부 ( true 인 경우 팝업 없어지지 않음 )

      // 돌발팝업 지속 여부 판별
      if (!eventPopupKeepYn) {
        // 사용자가 직접 시간 설정했을 경우 실행
        const timer =
          this.$store.getters["verticalMenu/adminUser"].eventPopupTimer;

        // setTimeout(() => {
        //   this.nextData(no);
        //   this.$emit("open-modal", false);
        // }, 1000);
        setTimeout(() => {
          this.nextData(no);
          this.$emit("open-modal", false);
        }, timer * 1000);
      }
    },
    nextData: async function (no) {
      this.sync = false;
      for (let i in this.alarm_obj) {
        if (this.alarm_obj[i].alarmTargetNo == no) {
          this.alarm_obj[i].check = false;
        }
      }
      const filteredArr = this.alarm_obj.filter(
        (item) => item.alarmTargetNo !== no
      );

      // splice() 메서드를 사용하여 기존 배열을 변경
      this.alarm_obj.splice(0, this.alarm_obj.length, ...filteredArr);
    },
    closebutton: function () {
      // 알람창 끄기 (확인버튼)
      window.URL.revokeObjectURL(this.sound_url);
      this.alarm_obj.shift();

      this.$emit("open-modal", false);
    },
    alarmClickEvent: function () {
      this.nextData();
    },
    // addAlarm: function (data) {
    //   var me = this;
    //   var linkName = this.tunnel_title[data.LinkCode];
    //   var dEventType = data.DEventType;
    //   var eventTypeName = me.event_type[dEventType];
    //   var radarContent = "";
    //   var cameraContent = "";
    //   var soundContent = "";
    //   var videoUrl = "";
    //   var position = data.Position == null ? 0 : data.Position;

    //   if (data.tech == "S") {
    //     // 사운드
    //     soundContent =
    //       eventTypeName == null
    //         ? position + "k에서 검지"
    //         : position + "k에서 " + eventTypeName + " 검지";
    //   } else if (data.tech == "R") {
    //     //돌발
    //     if (linkName == null || linkName == undefined || linkName == "") {
    //       radarContent =
    //         eventTypeName == null
    //           ? position + "k에서 검지"
    //           : position + "k에서 " + eventTypeName + " 검지";
    //     } else {
    //       radarContent =
    //         linkName + " " + position + "k에서 " + eventTypeName + " 검지";
    //     }
    //     videoUrl = data.RecVideoUrl;
    //   } else if (data.tech == "C") {
    //     //영상
    //     cameraContent =
    //       eventTypeName == null
    //         ? position + "k에서 검지"
    //         : position + "k에서 " + eventTypeName + " 검지";
    //   }

    //   me.alarmTargetNo++;
    //   me.setIntervalT(me.alarmTargetNo);
    //   me.alarm_obj.push({
    //     no: data.no,
    //     d_event_type_nm: eventTypeName,
    //     link_code_nm: linkName,
    //     title: linkName == null ? eventTypeName : eventTypeName + "_" + linkName,
    //     occur_time: me.convertDateFormat(data.OccurTime),
    //     tech: data.tech == "" ? "R" : data.tech,
    //     DEventType: dEventType,
    //     section: data.section == "" ? "A" : data.section,
    //     radarContent: radarContent,
    //     cameraContent: cameraContent,
    //     soundContent: soundContent,
    //     videoUrl: videoUrl,
    //     elevation: elevation,
    //     alarmTargetNo:me.alarmTargetNo,
    //     alramalias: data.alramalias,
    //     check:true
    //   });
    // },
    convertDateSoundFormat(dateStr) {
      var splitDate = dateStr.split(" ");
      var splitTime;
      if (splitDate.length > 1) {
        splitTime = splitDate[1].split("-");
      } else {
        splitTime = "00-00-00".split("-");
      }
      return (
        splitDate[0] +
        " " +
        splitTime[0] +
        ":" +
        splitTime[1] +
        ":" +
        splitTime[2]
      );
    },
    convertDateFormat(dateStr) {
      var year = dateStr.substring(0, 4);
      var month = dateStr.substring(4, 6);
      var day = dateStr.substring(6, 8);
      var hour = dateStr.substring(8, 10);
      var minute = dateStr.substring(10, 12);
      var second = dateStr.substring(12, 14);
      return (
        year +
        "-" +
        month +
        "-" +
        day +
        " " +
        hour +
        ":" +
        minute +
        ":" +
        second
      );
    },
    convertDateFormat2(dateStr) {
      var year = dateStr.substring(0, 4);
      var month = dateStr.substring(4, 6);
      var day = dateStr.substring(6, 8);
      var hour = dateStr.substring(8, 10);
      var minute = dateStr.substring(10, 12);
      var second = dateStr.substring(12, 14);

      return (
        year +
        "-" +
        month +
        "-" +
        day +
        " " +
        hour +
        ":" +
        minute +
        ":" +
        second
      );
    },
    async getActvieScheduleList() {
      var vue = this;
      try {
        const res = await this.api.getActiveSchedule({
          tunnelCode: this.selectTunnelCode,
        });
        const list = Array.from(res.data.data.scheduleList);
        vue.scheduleList = list.filter((schedule) => {
          // 공사일정 일자 표시 범위 설정 구간
          const date = new Date();

          // 공사일정 일자 범위 설정
          const startDate = new Date(
            schedule.startDate + " " + schedule.startTime
          );
          const endDate = new Date(schedule.endDate + " " + schedule.endTime);

          // const startTime = new Date(date);
          // const endTime = new Date(date);

          // // 공사일정 시,분,초 범위 설정
          // startTime.setHours(schedule.startTime.split(":")[0], schedule.startTime.split(":")[1], 0); // setHours(시, 분, 초)
          // endTime.setHours(schedule.endTime.split(":")[0], schedule.endTime.split(":")[1], 0);

          // startTime.setHours(schedule.startTime.split(":")[0], 0, 0);
          // endTime.setHours(schedule.endTime.split(":")[0], 0, 0);

          return startDate <= date && endDate >= date;
        });
      } catch (err) {}
    },
    // 돌발발생 위치와 공사이정 위치가 겹치는지 비교
    findSchedule: function (position, bound) {
      var date = new Date();
      var scheduleList = this.scheduleList;
      var deleteScheduleList = [];
      var currentTime = date.getTime();

      if (this.selectTunnelCode == "DBS") {
        bound = 0;
      }

      var result = false;
      if (scheduleList == null || scheduleList.length == 0) return false;

      // 공사일정 리스트 확인 로직
      for (var i = 0; i < scheduleList.length; i++) {
        var schedule = scheduleList[i];

        // 공사일정과 같지 않은 방면정보라면 {{ $t("button.B0009") }} 공사일정 확인
        if (schedule.bound != bound) continue;

        // 공사일정과 같은 방면일경우 로직 실행
        var endTimeStr = schedule.endTime.trim().split(":");
        var endDate = new Date(schedule.endDate.trim());
        endDate.setHours(endTimeStr[0], endTimeStr[1], 0);

        // 공사가 끝났을 경우 실행
        if (endDate.getTime() < currentTime) {
          deleteScheduleList.push(i);
          continue;
        }

        var startTime = new Date(date);
        var endTime = new Date(date);

        var startTimeStr = schedule.startTime.trim().split(":");

        startTime.setHours(startTimeStr[0], startTimeStr[1], 0);
        endTime.setHours(endTimeStr[0], endTimeStr[1], 0);

        if (
          !(
            startTime.getTime() <= currentTime &&
            endTime.getTime() >= currentTime
          )
        ) {
          continue;
        }

        var startPosition = parseFloat(schedule.startPosition);
        var endPosition = parseFloat(schedule.endPosition);
        var p = parseFloat(position); // 돌발 발생 위치

        // 돌발발생의 위치가 공사중인 위치라면 실행
        if (startPosition <= p && endPosition >= p) {
          // console.log('돌발위치와 공사 위치 같음');
          if (schedule.bound == bound)
            // console.log('돌발위치와 공사 위치 같음 => 방면도 같음 ')
            // 방면 추가
            result = true;
          break;
        }
      }

      // 공사가 끝난 스케쥴 리스트
      for (var i = 0; i < deleteScheduleList.length; i++) {
        scheduleList.splice(deleteScheduleList[i] - i, 1);
      }

      // 공사 일정과 겹친다면 true, 겹치지 않는다면 false
      return result;
    },
    activeScheduleTimer: function () {
      var date = new Date();
      var next = new Date();

      next.setHours(next.getHours() + 1, 0);
      next.setSeconds(0, 10);

      var time = next - date;

      this.scheduleTimer = setTimeout(() => {
        this.getActvieScheduleList();
        this.activeScheduleTimer();
      }, time);
    },
    // 돌발 발생시 검지 장비의 방면 정보 가져오는 메소드
    findEquipBound: function (cameraId) {
      var me = this;
      var result = 0;
      // var result = "1";
      var equipList = me.equipList;
      for (var i = 0; equipList.length; i++) {
        if (equipList[i].cameraId == cameraId) {
          result = equipList[i].bound;
          break;
        }
      }
      return Number(result);
      // return result == "1" ? 0 : 1;
    },

    playSound(eventTypeSetting) {
      if (this.sound_url != "") {
        console.log("==========check============", this.sound_url);
        const soundEle = document.getElementById("audio"); // <audio> 태그의 속성과 그 값을 soundEle에 할당
        // localStorage에 있는 audioSoundType 가져옴 (audioSoundType에는 eventType이라는 이름으로 각 돌발유형 별 볼륨값 저장)
        var audioSoundType = localStorage.getItem("audioSoundType");
        const obj = JSON.parse(audioSoundType); // String Type의 json을 object 타입으로 변환
        soundEle.src = this.sound_url;

        // {{ $t("label.L0044") }} 타입에 맞춰 볼륨 세팅
        // console.log("🚀 ~ playSound ~ this.eventTypeSetting:", this.eventTypeSetting);
        // switch (this.eventTypeSetting) {
        switch (eventTypeSetting) {
          case "01":
            soundEle.volume = obj.eventType[0].stoppedvehicleVal;
            break;
          case "02":
            soundEle.volume = obj.eventType[0].pedestrian;
            break;
          case "03":
            soundEle.volume = obj.eventType[0].wrongway;
            break;
          case "04":
            soundEle.volume = obj.eventType[0].sudden_stop;
            break;
          case "06":
            soundEle.volume = obj.eventType[0].fire;
            break;
          case "09":
            soundEle.volume = obj.eventType[0].jam;
            break;
          case "1":
            soundEle.volume = obj.eventType[0].stoppedvehicleVal;
            break;
          case "2":
            soundEle.volume = obj.eventType[0].pedestrian;
            break;
          case "3":
            soundEle.volume = obj.eventType[0].wrongway;
            break;
          case "4":
            soundEle.volume = obj.eventType[0].sudden_stop;
            break;
          case "6":
            soundEle.volume = obj.eventType[0].fire;
            break;
          case "9":
            soundEle.volume = obj.eventType[0].jam;
            break;
          case "11":
            soundEle.volume = obj.eventType[0].accident_sound;
            break;
          case "12":
            soundEle.volume = obj.eventType[0].horn_sound;
            break;
          case "13":
            soundEle.volume = obj.eventType[0].sudden_sound;
            break;
          case "14":
            soundEle.volume = obj.eventType[0].noise;
            break;
          default:
            soundEle.volume = 0;
          // soundEle.volume = 1;
        }

        // // 방법 1
        // // canplaythrough {{ $t("label.L0044") }}는 미디어가 {{$t("label.L0021")}} 재생 가능한 상태로 로드되었을 때 발생
        // // 해당 {{ $t("label.L0044") }}가 발생하면 미디어 재생
        // soundEle.addEventListener('canplaythrough', function () {
        //   soundEle.play();
        // });

        // // 방법 2
        // soundEle.load(); // 미디어 파일 load
        // soundEle.pause(); // 자동재생 일시중지 (muted 속성으로 인해 자동재생이 되기 때문에, load가 완료되기 전에 무작정 play되는 것을 방지)
        // soundEle.play(); // 미디어 재생, 하지만 실실적으로 pause()를 했다고 해도 soundEle.play()는 재생할 준비가 되지 않은 경우가 발생하기도 함, 때문에 아래 처럼 변수에 담아서 실행

        const promise = soundEle.play(); // play()는 promise 객체 반환
        if (promise !== undefined) {
          // promise에 저장된 결과 상태에 따라 하단 분기 실행
          promise
            .then(() => {
              // soundEle.play();가 재생할 준비가 되었으면
              // Autoplay started!
              soundEle.pause();
              soundEle.play(); // 미디어 재생
            })
            .catch(() => {
              // soundEle.play();가 되지 않았으면
              // Autoplay was prevented

              soundEle.pause();
              soundEle.play(); // 미디어 재생
            });
        }
      }
    },
    playSound2(fileUrl) {
      const soundEle = document.getElementById("audio");
      soundEle.src = fileUrl;

      document.getElementById("audio").load();
      document.getElementById("audio").pause();
      document.getElementById("audio").play();
    },
    // 새 돌발{{ $t("label.L0044") }}(data) 발생시 DB 조회
    async getNewEventInfoList(data) {
      let me = this;

      // 돌발카운트, 이상검지이력 호출
      EventBus.$emit("eventTable", data);

      // 돌발발생 위치와 공사 위치가 겹친다면 실행
      if (me.findSchedule(data.Position, data.bound)) {
        return;
      }

      var startPosition = parseFloat(data.Position) - 0.05;
      var endPosition = parseFloat(data.Position) + 0.05;

      if (data.test == "dev") {
        // 돌발 DB insert
        me.api.addDemoEventData({
          occurTime: data.OccurTime,
          cameraId: data.CameraID,
          dEventType: data.DEventType,
          position: data.Position,
        });

        try {
          const res = await me.api.selectNewEventInfoList({
            camera_id: data.CameraID,
            start_position: startPosition,
            end_position: endPosition,
            occur_time: data.OccurTime,
          });

          let event_info_list = res.data.eventInfoList;

          if (event_info_list.length == 0) {
            var demo_tech_nm = null;
            if (data.tech == "R") {
              demo_tech_nm = this.$t("label.L0029");
            } else if (data.tech == "C") {
              demo_tech_nm = this.$t("label.L0031");
            } else if (data.tech == "S") {
              demo_tech_nm = this.$t("label.L0030");
            }
            var demo_event_info_list = [
              {
                alramalias: data.CameraID,
                bound: data.bound,
                camera_id: data.CameraID,
                DEventType: data.DEventType,
                d_event_type: data.DEventType,
                link_code: data.LinkCode,
                occur_time: data.OccurTime,
                occur_time_string: me.convertDateFormat2(data.OccurTime),
                position: data.Position,
                tech: data.tech,
                tech_nm: demo_tech_nm,
              },
            ];
          }

          me.checkMix2(
            data,
            event_info_list.length == 0 ? demo_event_info_list : event_info_list
          );
        } catch (err) {}
        // me.checkMix2(data, event_info_list);
      } else {
        try {
          const res = await me.api.selectNewEventInfoList({
            camera_id: data.CameraID,
            start_position: startPosition,
            end_position: endPosition,
            occur_time: data.OccurTime,
          });

          let event_info_list = res.data.eventInfoList;
          me.checkMix2(data, event_info_list);
        } catch (err) {}
      }
    },
    checkMix2: function (data, eventInfoList) {
      //EventBus.$emit("audioSoundSetting", data.DEventType); // 음성 재생

      //돌발유형 타입 담기
      this.eventTypeSetting = data.DEventType;
      // this.eventTypeSetting = eventInfoList.d_event_type;
      // data : 새 돌발 {{ $t("label.L0044") }}
      // eventInfoList : 새 돌발 {{ $t("label.L0044") }} 기준의 리스트 (from DB)
      // me.alarm_obj : 알림창
      // alarmList : 알림창의 리스트
      // 기존 alarmList 정보에서 {{ $t("label.L0043") }}<로직을 루프를 돌아서 확인
      let me = this;
      // 기존인지 아닌지에 대한 리턴
      var existFlag = false;
      // linkCode에 현재 선택된 터널코드가 포함되어 있는 경우에만 돌발알람 로직 실행
      // {{$t("label.L0125")}} {{ $t("label.L0044") }} 발생 시 화면전환, 카메라전환
      var tunnelCode = data.CameraID.substr(0, 3);

      if (
        this.autoChangeEventList.includes(data.DEventType) &&
        this.selectTunnelCode != tunnelCode
      ) {
        EventBus.$emit("eventTunnelMove", tunnelCode, true);
        // EventBus.$emit('occurEvent',data,tunnelCode);
      }

      if (data.CameraID.includes(this.selectTunnelCode)) {
        //첫 돌발{{ $t("label.L0044") }}
        if (me.alarm_obj.length == 0) {
          data.alramalias = eventInfoList[0].alramalias;
          me.addAlarm2(data, eventInfoList);
        } else {
          if (eventInfoList !== null) {
            for (let i = 0; i < me.alarm_obj.length; i++) {
              // 기존 알람리스트의 bound, position 과 새 돌발{{ $t("label.L0044") }}의 bound, position 비교
              // position +- 100m 범위안이면 같은 {{ $t("label.L0044") }}로 봄
              // 영상 분석기인 경우 발생 위치(position),영상 정보 없음
              if (
                me.alarm_obj[i].bound == data.bound &&
                parseFloat(
                  (parseFloat(me.alarm_obj[i].position) - 0.05).toFixed(2)
                ) <= parseFloat(data.Position) &&
                parseFloat(
                  (parseFloat(me.alarm_obj[i].position) + 0.05).toFixed(2)
                ) >= parseFloat(data.Position)
              ) {
                if (
                  new Date(me.convertDateFormat(eventInfoList[0].occur_time)) -
                    new Date(me.convertDateFormat(me.alarm_obj[i].occur_time)) <
                  300000
                ) {
                  // 5분이내 -> 같은 {{ $t("label.L0044") }}로 판단 -> 리스트 업데이트
                  //me.alarm_obj[i].occur_time = me.data.OccurTime,
                  //me.alarm_obj[i].occur_time_string = me.convertDateFormat2(data.OccurTime),
                  var arrr = me.addAlarmList(eventInfoList);
                  arrr.forEach((each) => {
                    me.alarm_obj[i].objalarmList.push(each);
                  });
                  existFlag = true;
                  break;
                }
              }
            }
          }
          //기존 알람이 아닐경우
          if (!existFlag) {
            //새 돌발 {{ $t("label.L0044") }}로 판단
            for (var i = 0; i < eventInfoList.length; i++) {
              if (data.no == eventInfoList[i].no) {
                data.alramalias = eventInfoList[i].alramalias;
              }
            }
            me.addAlarm2(data, eventInfoList);
          }
          this.$forceUpdate();
        }

        // 대시보드 돌발 circle 표시
        EventBus.$emit(
          "occurEvent",
          data,
          tunnelCode,
          this.popupCancleEventList
        );

        EventBus.$emit(
          "audioSoundSetting",
          this.selectSoundUrl(data.DEventType),
          data.DEventType
        ); // 음성 재생

        if (this.popupCancleEventList.includes(data.DEventType)) {
          // 알람 표출 안함
          var videoFrame = document.getElementById("eventModalVideoFrame");
          videoFrame.style.background = "black";
          return;
        }

        // 팝업창 열기.
        // console.log("🚀 ~ open-moda 3mix ")
        me.$emit("open-modal", true);
      }
    },
    selectSoundUrl(DEventType) {
      let soundUrl = null;

      switch (DEventType) {
        case "01": {
          soundUrl = "resources/video/alarm_stoppedvehicle.mp3";
          break;
        }
        case "02": {
          soundUrl = "resources/video/alarm_pedestrian.mp3";
          break;
        }
        case "03": {
          soundUrl = "resources/video/alarm_wrongway.mp3";
          break;
        }
        case "04": {
          soundUrl = "resources/video/alarm_sudden_stop.mp3";
          break;
        }
        case "06": {
          soundUrl = "resources/video/alarm_fire.mp3";
          break;
        }
        case "09": {
          soundUrl = "resources/video/alarm_jam.mp3";
          break;
        }
        case "1": {
          soundUrl = "resources/video/alarm_stoppedvehicle.mp3";
          break;
        }
        case "2": {
          soundUrl = "resources/video/alarm_pedestrian.mp3";
          break;
        }
        case "3": {
          soundUrl = "resources/video/alarm_wrongway.mp3";
          break;
        }
        case "4": {
          soundUrl = "resources/video/alarm_sudden_stop.mp3";
          break;
        }
        case "6": {
          soundUrl = "resources/video/alarm_fire.mp3";
          break;
        }
        case "9": {
          soundUrl = "resources/video/alarm_jam.mp3";
          break;
        }
        case null: {
          soundUrl = "";
        }
      }

      return soundUrl;
    },
    addAlarm2: function (data, eventInfoList) {
      var me = this;
      var idx = me.alarm_obj.length;
      me.alarmTargetNo++;
      me.setIntervalT(me.alarmTargetNo);
      var tech_nm = null;
      if (data.tech == "R") {
        tech_nm = this.$t("label.L0029");
      } else if (data.tech == "C") {
        tech_nm = this.$t("label.L0031");
      } else if (data.tech == "S") {
        tech_nm = this.$t("label.L0030");
      }
      me.alarm_obj.push({
        no: data.no,
        camera_id: data.CameraID,
        occur_time: data.OccurTime,
        occur_time_string: me.convertDateFormat2(data.OccurTime),
        tech: data.tech,
        tech_nm: tech_nm,
        DEventType: data.DEventType,
        d_event_type_nm: me.event_type[data.DEventType],
        // link_code_nm: me.selectTunnelInfo[data.bound].direction,
        link_code_nm:
          data.bound == 1 ? this.$t("label.L0019") : this.$t("label.L0020"), // direction 1:상행 0:하행
        bound: data.bound,
        position: data.Position,
        rec_video_url: data.RecVideoUrl,
        alramalias: data.alramalias,
        elevation: data.Elevation,
        alarmTargetNo: me.alarmTargetNo,
        check: true,
      });

      // 각 알람의 리스트 {{ $t("label.L0043") }}<
      me.alarm_obj[idx].objalarmList = me.addAlarmList(eventInfoList);

      if (idx == 0) {
        me.eventShowVideo();
      }
    },
    addAlarmList: function (eventInfoList) {
      var me = this;
      me.alarmList = []; // 리

      for (let j = 0; j < eventInfoList.length; j++) {
        let newAlarm = eventInfoList[j];
        var tech_nm = null;
        if (newAlarm.tech == "R") {
          tech_nm = this.$t("label.L0029");
        } else if (newAlarm.tech == "C") {
          tech_nm = this.$t("label.L0031");
        } else if (newAlarm.tech == "S") {
          tech_nm = this.$t("label.L0030");
        }
        me.alarmList.push({
          no: newAlarm.no,
          occur_time: newAlarm.occur_time,
          occur_time_string: newAlarm.occur_time_string,
          tech: newAlarm.tech,
          // tech_nm: newAlarm.tech_nm,
          tech_nm: tech_nm,
          d_event_type_nm: me.event_type[newAlarm.d_event_type],
          videoUrl: newAlarm.rec_video_url,
          // link_code_nm: me.selectTunnelInfo[newAlarm.bound].direction,
          link_code_nm:
            newAlarm.bound == 1
              ? this.$t("label.L0019")
              : this.$t("label.L0020"), // direction 1:상행 0:하행
          bound: newAlarm.bound,
          position: newAlarm.position.toFixed(2) * 1000 + " m",
          elevation: newAlarm.Elevation,
          camera_id: newAlarm.camera_id,
        });
      }
      return me.alarmList;
    },
    showDemoCctv: function (demoEventType) {
      let videoElement = document.getElementById("alramVideo");
      videoElement.src = require("@/assets/video/event/" +
        demoEventType +
        ".mp4");
    },
    showRealCctv: function (alramAlias) {
      this.player = new UnrealWebRTCPlayer(
        "alramVideo",
        alramAlias,
        "",
        this.mediaServerInfo.ip,
        this.mediaServerInfo.port,
        false,
        true,
        "tcp"
      );
      // this.player.Play();
    },
    async eventShowVideo() {
      var me = this;
      if (me.alarm_obj.length == 0) return;

      var event = me.alarm_obj[0];
      try {
        // const response = await this.api.searchAlramRtsp({
        //   no: event.no,
        //   //  cameraId: event.camera_id,
        //   //   position: event.position
        // });
        // var data = response.data;
        var demoEventType = event.DEventType;
        // var demoEventType = event.DEventType;
        // var alramAlias = data.data.alramAlias;

        // 기존
        // this.showRealCctv(alramAlias);

        // 데모버전
        this.$nextTick(() => {
          this.showDemoCctv(demoEventType);
        });
      } catch (err) {
        console.log("🚀 ~ eventShowVideo ~ err:", err);
        var videoFrame = document.getElementById("eventModalVideoFrame");
        videoFrame.style.background = "black";
      }
    },
    async getEquipList() {
      var vue = this;
      try {
        const response = await this.api.selectEquipDashboardList();
        const { data } = response.data;
        vue.equipList = data.equipList;
      } catch (err) {
        console.log("errorrror : ", err);
      }
    },
  },
  created() {
    EventBus.$on("selectTunnelName", (val) => {
      this.selectTunnelName = val;
    });
    EventBus.$on("selectTunnelCode", (val) => {
      this.selectTunnelCode = val;
      this.getActvieScheduleList();
    });
    // state.vue에서 현재 선택된 터널 정보를 받아옴
    EventBus.$on("selectTunnelInfo", (val) => {
      this.selectTunnelInfo = val;
    });
    // state.vue에서 현재 선택된 터널 정보를 받아옴
    EventBus.$on("demoEvent", (val) => {
      this.getNewEventInfoList(val);
    });
    var me = this;
    this.activeScheduleTimer();
    this.getEquipList();

    // 돌발 발생시 실행
    // this.socket.addListener(this, "threeMix", function (data) {
    //   // 돌발카운트, 이상검지이력 호출
    //   var socket = me.socket;
    //   var listenerList = socket.listeners["eventTable"];
    //   for (var i = 0; i < listenerList.length; i++) {
    //     var listener = listenerList[i];
    //     if (!listener.func instanceof Function) continue;
    //     try {
    //       listener.func(data);
    //     } catch (e) {
    //       //console.log(e);
    //     }
    //   }

    //   // 돌발 발생한 장비의 방면정보를 기준으로 상하행 결정. -> 상행 장비면 상행으로 ( 만약 없으면 무조건 bound 0 )
    //   // var bound = me.findEquipBound(data.CameraID);

    //   // 돌발발생 위치와 공사 위치가 겹친다면 실행
    //   if (me.findSchedule(data.Position, data.bound)) {
    //     return;
    //   }

    //   //돌발{{ $t("label.L0044") }} 발생시, 해당 돌발{{ $t("label.L0044") }}의 camera_id로 DB데이터 가져오기
    //   //도로 위 돌발아이콘 표시여부 관련 상태값 전달
    //   EventBus.$emit("findSchedule", true);

    //   // 팝업창 열기.
    //   // me.$emit("open-modal", true);
    //   me.getNewEventInfoList(data); //돌발{{ $t("label.L0044") }} 발생시, 해당 돌발{{ $t("label.L0044") }}의 camera_id로 DB데이터 가져오기

    //   // 카메라 icon 변경
    //   EventBus.$emit("changeCamera", data.CameraID, this.selectTunnelCode);

    //   //{{ $t("label.L0044") }} 아이콘 관련 호출
    //   // var socket = me.socket;
    //   // var listenerList = socket.listeners["event"];
    //   // for(var i = 0; i < listenerList.length; i++) {
    //   //   var listener = listenerList[i];
    //   //   if(!listener.func instanceof Function) continue;
    //   //   try {
    //   //     listener.func(data);
    //   //   } catch(e) {
    //   //     //console.log(e);
    //   //   }
    //   // }

    //   // var tunnelCode = null;
    //   // // {{$t("label.L0022")}},{{$t("label.L0023")}},{{$t("label.L0024")}} {{ $t("label.L0044") }} 발생 시 해당 터널로 이동
    //   // if(data.DEventType == "02" || data.DEventType == "03" || data.DEventType == "06"){
    //   //   tunnelCode = data.CameraID.substr(0, 3);
    //   //   EventBus.$emit('eventTunnelMove',tunnelCode,true);
    //   //   EventBus.$emit('occurEvent',data,tunnelCode);
    //   // }
    //   //   console.log("::::::::::::",data.CameraID);
    //   //   console.log("::::::::::::",this.selectTunnelCode);
    //   // if (data.CameraID.includes(this.selectTunnelCode)) {
    //   //   console.log("::::::::::::",data.CameraID);
    //   //   console.log("::::::::::::",this.selectTunnelCode);
    //   //   // 돌발아이콘 {{ $t("label.L0044") }} 버스 보낸다.
    //   //   EventBus.$emit('occurEvent',data,tunnelCode);
    //   // }
    // });

    // 돌발 발생시 실행
    this.socket.addListener(this, "demoEvent", function (data) {
      // 돌발카운트, 이상검지이력 호출
      var socket = me.socket;
      var listenerList = socket.listeners["eventTable"];
      for (var i = 0; i < listenerList.length; i++) {
        var listener = listenerList[i];
        if (!listener.func instanceof Function) continue;
        try {
          listener.func(data);
        } catch (e) {
          //console.log(e);
        }
      }

      // 돌발발생 위치와 공사 위치가 겹친다면 실행
      if (me.findSchedule(data.Position, data.bound)) {
        return;
      }
      me.getNewEventInfoList(data);
    });

    // this.socket.addListener(this, "updateSchedule", function (data) {
    //   me.getActvieScheduleList();
    // });

    EventBus.$on("updateSchedule", () => {
      me.getActvieScheduleList();
    });

    // // 사운드
    // this.socket.addListener(this, "wrongSoundInfo", function (data) {
    //   var deviceId = data.deviceId;
    //   var wrongSound = data.wrongSound;
    //   var occurTime = data.occurTime;
    //   // 여기에 값 세팅
    //   var soundData = {
    //     LinkCode: deviceId,
    //     DEventType: wrongSound,
    //     Position: "1",
    //     OccurTime: occurTime,
    //     tech: "S",
    //   };

    //   if (me.findSchedule(soundData.Position, 0)) return;
    //   if (me.findSchedule(soundData.Position, 1)) return;
    //   // me.addAlarm(soundData);

    //   // 여기서 코드값을 챙겨오면 됨
    //   EventBus.$emit("stopSoundTimer", deviceId);
    //   me.sound_code = deviceId;
    // });

    this.api.getMediaServerConfig().then(function (res) {
      var data = res.data;
      me.mediaServerInfo.ip = data.data["ip"];
      me.mediaServerInfo.port = data.data["port"];
    });

    EventBus.$on("audioSoundSetting", (sound_url, eventTypeSetting) => {
      this.eventTypeSetting = eventTypeSetting;
      this.sound_url = sound_url;
      this.playSound(eventTypeSetting);
    });

    // state.vue에서 현재 선택된 터널 정보를 받아옴
    EventBus.$on("canclePopupEventList", (val) => {
      this.popupCancleEventList = val;
    });
  },
  mounted() {
    this.audioTag = document.getElementById("audio");
    EventBus.$on("autoChangeEventList", (autoChangeEventList) => {
      this.autoChangeEventList = autoChangeEventList;
    });
    EventBus.$on("popupCancleEventList", (popupCancleEventList) => {
      this.popupCancleEventList = popupCancleEventList;
    });
  },
  watch: {
    alarm_obj: function (newVal, oldVal) {
      var sTech = "";
      if (newVal.length > 0) {
        sTech = this.alarm_obj[0].tech;
      }
      if (this.alarm_obj.length > 0) {
        if (this.popupCancleEventList.includes(this.alarm_obj[0].DEventType)) {
          return;
        }
        switch (this.alarm_obj[0].DEventType) {
          case "01": {
            this.sound_url = "resources/video/alarm_stoppedvehicle.mp3";
            break;
          }
          case "02": {
            this.sound_url = "resources/video/alarm_pedestrian.mp3";
            break;
          }
          case "03": {
            this.sound_url = "resources/video/alarm_wrongway.mp3";
            break;
          }
          case "04": {
            this.sound_url = "resources/video/alarm_sudden_stop.mp3";
            break;
          }
          case "06": {
            this.sound_url = "resources/video/alarm_fire.mp3";
            break;
          }
          case "09": {
            this.sound_url = "resources/video/alarm_jam.mp3";
            break;
          }
          case "1": {
            this.sound_url = "resources/video/alarm_stoppedvehicle.mp3";
            break;
          }
          case "2": {
            this.sound_url = "resources/video/alarm_pedestrian.mp3";
            break;
          }
          case "3": {
            this.sound_url = "resources/video/alarm_wrongway.mp3";
            break;
          }
          case "4": {
            this.sound_url = "resources/video/alarm_sudden_stop.mp3";
            break;
          }
          case "6": {
            this.sound_url = "resources/video/alarm_fire.mp3";
            break;
          }
          case "9": {
            this.sound_url = "resources/video/alarm_jam.mp3";
            break;
          }
          case null: {
            this.sound_url = "";
          }
        }
        // if (this.alarm_obj.length > 0 && sTech != "S") {
        // if (this.alarm_obj[0].camera_id.includes("GRR")) {
        //   switch (this.alarm_obj[0].DEventType) {
        //     case "01": {
        //       this.sound_url =
        //         "resources/video/alarm_stoppedvehicle_01_GRR.m4a";
        //       break;
        //     }
        //     case "02": {
        //       this.sound_url = "resources/video/alarm_pedestrian_02_GRR.m4a";
        //       break;
        //     }
        //     case "03": {
        //       this.sound_url = "resources/video/alarm_wrongway_03_GRR.m4a";
        //       break;
        //     }
        //     case "04": {
        //       this.sound_url = "resources/video/alarm_sudden_stop_04_GRR.m4a";
        //       break;
        //     }
        //     case "06": {
        //       this.sound_url = "resources/video/alarm_fire_06_GRR.m4a";
        //       break;
        //     }
        //     case "09": {
        //       this.sound_url = "resources/video/alarm_jam_09_GRR.m4a";
        //       break;
        //     }
        //     case "1": {
        //       this.sound_url =
        //         "resources/video/alarm_stoppedvehicle_01_GRR.m4a";
        //       break;
        //     }
        //     case "2": {
        //       this.sound_url = "resources/video/alarm_pedestrian_02_GRR.m4a";
        //       break;
        //     }
        //     case "3": {
        //       this.sound_url = "resources/video/alarm_wrongway_03_GRR.m4a";
        //       break;
        //     }
        //     case "4": {
        //       this.sound_url = "resources/video/alarm_sudden_stop_04_GRR.m4a";
        //       break;
        //     }
        //     case "6": {
        //       this.sound_url = "resources/video/alarm_fire_06_GRR.m4a";
        //       break;
        //     }
        //     case "9": {
        //       this.sound_url = "resources/video/alarm_jam_09_GRR.m4a";
        //       break;
        //     }
        //     case null: {
        //       this.sound_url = "";
        //     }
        //   }
        // } else if (this.alarm_obj[0].camera_id.includes("YKR")) {
        //   switch (this.alarm_obj[0].DEventType) {
        //     case "01": {
        //       this.sound_url =
        //         "resources/video/alarm_stoppedvehicle_01_YKR.m4a";
        //       break;
        //     }
        //     case "02": {
        //       this.sound_url = "resources/video/alarm_pedestrian_02_YKR.m4a";
        //       break;
        //     }
        //     case "03": {
        //       this.sound_url = "resources/video/alarm_wrongway_03_YKR.m4a";
        //       break;
        //     }
        //     case "04": {
        //       this.sound_url = "resources/video/alarm_sudden_stop_04_YKR.m4a";
        //       break;
        //     }
        //     case "06": {
        //       this.sound_url = "resources/video/alarm_fire_06_YKR.m4a";
        //       break;
        //     }
        //     case "09": {
        //       this.sound_url = "resources/video/alarm_jam_09_YKR.m4a";
        //       break;
        //     }
        //     case "1": {
        //       this.sound_url =
        //         "resources/video/alarm_stoppedvehicle_01_YKR.m4a";
        //       break;
        //     }
        //     case "2": {
        //       this.sound_url = "resources/video/alarm_pedestrian_02_YKR.m4a";
        //       break;
        //     }
        //     case "3": {
        //       this.sound_url = "resources/video/alarm_wrongway_03_YKR.m4a";
        //       break;
        //     }
        //     case "4": {
        //       this.sound_url = "resources/video/alarm_sudden_stop_04_YKR.m4a";
        //       break;
        //     }
        //     case "6": {
        //       this.sound_url = "resources/video/alarm_fire_06_YKR.m4a";
        //       break;
        //     }
        //     case "9": {
        //       this.sound_url = "resources/video/alarm_jam_09_YKR.m4a";
        //       break;
        //     }
        //     case null: {
        //       this.sound_url = "";
        //     }
        //   }
        // } else if (this.alarm_obj[0].camera_id.includes("KHR")) {
        //   switch (this.alarm_obj[0].DEventType) {
        //     case "01": {
        //       this.sound_url =
        //         "resources/video/alarm_stoppedvehicle_01_KHR.m4a";
        //       break;
        //     }
        //     case "02": {
        //       this.sound_url = "resources/video/alarm_pedestrian_02_KHR.m4a";
        //       break;
        //     }
        //     case "03": {
        //       this.sound_url = "resources/video/alarm_wrongway_03_KHR.m4a";
        //       break;
        //     }
        //     case "04": {
        //       this.sound_url = "resources/video/alarm_sudden_stop_04_KHR.m4a";
        //       break;
        //     }
        //     case "06": {
        //       this.sound_url = "resources/video/alarm_fire_06_KHR.m4a";
        //       break;
        //     }
        //     case "09": {
        //       this.sound_url = "resources/video/alarm_jam_09_KHR.m4a";
        //       break;
        //     }
        //     case "1": {
        //       this.sound_url =
        //         "resources/video/alarm_stoppedvehicle_01_KHR.m4a";
        //       break;
        //     }
        //     case "2": {
        //       this.sound_url = "resources/video/alarm_pedestrian_02_KHR.m4a";
        //       break;
        //     }
        //     case "3": {
        //       this.sound_url = "resources/video/alarm_wrongway_03_KHR.m4a";
        //       break;
        //     }
        //     case "4": {
        //       this.sound_url = "resources/video/alarm_sudden_stop_04_KHR.m4a";
        //       break;
        //     }
        //     case "6": {
        //       this.sound_url = "resources/video/alarm_fire_06_KHR.m4a";
        //       break;
        //     }
        //     case "9": {
        //       this.sound_url = "resources/video/alarm_jam_09_KHR.m4a";
        //       break;
        //     }
        //     case null: {
        //       this.sound_url = "";
        //     }
        //   }
        // } else if (this.alarm_obj[0].camera_id.includes("SDR")) {
        //   switch (this.alarm_obj[0].DEventType) {
        //     case "01": {
        //       this.sound_url =
        //         "resources/video/alarm_stoppedvehicle_01_SDR.m4a";
        //       break;
        //     }
        //     case "02": {
        //       this.sound_url = "resources/video/alarm_pedestrian_02_SDR.m4a";
        //       break;
        //     }
        //     case "03": {
        //       this.sound_url = "resources/video/alarm_wrongway_03_SDR.m4a";
        //       break;
        //     }
        //     case "04": {
        //       this.sound_url = "resources/video/alarm_sudden_stop_04_SDR.m4a";
        //       break;
        //     }
        //     case "06": {
        //       this.sound_url = "resources/video/alarm_fire_06_SDR.m4a";
        //       break;
        //     }
        //     case "09": {
        //       this.sound_url = "resources/video/alarm_jam_09_SDR.m4a";
        //       break;
        //     }
        //     case "1": {
        //       this.sound_url =
        //         "resources/video/alarm_stoppedvehicle_01_SDR.m4a";
        //       break;
        //     }
        //     case "2": {
        //       this.sound_url = "resources/video/alarm_pedestrian_02_SDR.m4a";
        //       break;
        //     }
        //     case "3": {
        //       this.sound_url = "resources/video/alarm_wrongway_03_SDR.m4a";
        //       break;
        //     }
        //     case "4": {
        //       this.sound_url = "resources/video/alarm_sudden_stop_04_SDR.m4a";
        //       break;
        //     }
        //     case "6": {
        //       this.sound_url = "resources/video/alarm_fire_06_SDR.m4a";
        //       break;
        //     }
        //     case "9": {
        //       this.sound_url = "resources/video/alarm_jam_09_SDR.m4a";
        //       break;
        //     }
        //     case null: {
        //       this.sound_url = "";
        //     }
        //   }
        // } else if (this.alarm_obj[0].camera_id.includes("SBT")) {
        //   switch (this.alarm_obj[0].DEventType) {
        //     case "01": {
        //       this.sound_url =
        //         "resources/video/alarm_stoppedvehicle_01_SBT.m4a";
        //       break;
        //     }
        //     case "02": {
        //       this.sound_url = "resources/video/alarm_pedestrian_02_SBT.m4a";
        //       break;
        //     }
        //     case "03": {
        //       this.sound_url = "resources/video/alarm_wrongway_03_SBT.m4a";
        //       break;
        //     }
        //     case "04": {
        //       this.sound_url = "resources/video/alarm_sudden_stop_04_SBT.m4a";
        //       break;
        //     }
        //     case "06": {
        //       this.sound_url = "resources/video/alarm_fire_06_SBT.m4a";
        //       break;
        //     }
        //     case "09": {
        //       this.sound_url = "resources/video/alarm_jam_09_SBT.m4a";
        //       break;
        //     }
        //     case "1": {
        //       this.sound_url =
        //         "resources/video/alarm_stoppedvehicle_01_SBT.m4a";
        //       break;
        //     }
        //     case "2": {
        //       this.sound_url = "resources/video/alarm_pedestrian_02_SBT.m4a";
        //       break;
        //     }
        //     case "3": {
        //       this.sound_url = "resources/video/alarm_wrongway_03_SBT.m4a";
        //       break;
        //     }
        //     case "4": {
        //       this.sound_url = "resources/video/alarm_sudden_stop_04_SBT.m4a";
        //       break;
        //     }
        //     case "6": {
        //       this.sound_url = "resources/video/alarm_fire_06_SBT.m4a";
        //       break;
        //     }
        //     case "9": {
        //       this.sound_url = "resources/video/alarm_jam_09_SBT.m4a";
        //       break;
        //     }
        //     case "11": {
        //       this.sound_url =
        //         "resources/video/alarm_accident_sound_11_SBT.m4a";
        //       break;
        //     }
        //     case "12": {
        //       this.sound_url = "resources/video/alarm_horn_sound_12_SBT.m4a";
        //       break;
        //     }
        //     case "13": {
        //       this.sound_url = "resources/video/alarm_sudden_sound_13_SBT.m4a";
        //       break;
        //     }
        //     case "14": {
        //       this.sound_url = "resources/video/alarm_noise_14_SBT.m4a";
        //       break;
        //     }
        //     case null: {
        //       this.sound_url = "";
        //     }
        //   }
        // } else if (this.alarm_obj[0].camera_id.includes("DBS")) {
        //   switch (this.alarm_obj[0].DEventType) {
        //     case "01": {
        //       this.sound_url =
        //         "resources/video/alarm_stoppedvehicle_01_DBS.m4a";
        //       break;
        //     }
        //     case "02": {
        //       this.sound_url = "resources/video/alarm_pedestrian_02_DBS.m4a";
        //       break;
        //     }
        //     case "03": {
        //       this.sound_url = "resources/video/alarm_wrongway_03_DBS.m4a";
        //       break;
        //     }
        //     case "04": {
        //       this.sound_url =
        //         "resources/video/alarm_sudden_stop_new_04_DBS.m4a";
        //       break;
        //     }
        //     case "06": {
        //       this.sound_url = "resources/video/alarm_fire_06_DBS.m4a";
        //       break;
        //     }
        //     case "09": {
        //       this.sound_url = "resources/video/alarm_jam_09_DBS.m4a";
        //       break;
        //     }
        //     case "1": {
        //       this.sound_url =
        //         "resources/video/alarm_stoppedvehicle_01_DBS.m4a";
        //       break;
        //     }
        //     case "2": {
        //       this.sound_url = "resources/video/alarm_pedestrian_02_DBS.m4a";
        //       break;
        //     }
        //     case "3": {
        //       this.sound_url = "resources/video/alarm_wrongway_03_DBS.m4a";
        //       break;
        //     }
        //     case "4": {
        //       this.sound_url = "resources/video/alarm_sudden_stop_04_DBS.m4a";
        //       break;
        //     }
        //     case "6": {
        //       this.sound_url = "resources/video/alarm_fire_06_DBS.m4a";
        //       break;
        //     }
        //     case "9": {
        //       this.sound_url = "resources/video/alarm_jam_09_DBS.m4a";
        //       break;
        //     }
        //     case "11": {
        //       this.sound_url =
        //         "resources/video/alarm_accident_sound_11_DBS.m4a";
        //       break;
        //     }
        //     case "12": {
        //       this.sound_url = "resources/video/alarm_horn_sound_12_DBS.m4a";
        //       break;
        //     }
        //     case "13": {
        //       this.sound_url = "resources/video/alarm_sudden_sound_13_DBS.m4a";
        //       break;
        //     }
        //     case "14": {
        //       this.sound_url = "resources/video/alarm_noise_14_DBS.m4a";
        //       break;
        //     }
        //     case null: {
        //       this.sound_url = "";
        //     }
        //   }
        // }

        //  switch (this.alarm_obj[0].DEventType) {
        //     case "1": {
        //       this.sound_url = "resources/video/.m4a";
        //       break;
        //     }
        //     case "2": {
        //       this.sound_url = "resources/video/.m4a";
        //       break;
        //     }
        //     case "3": {
        //       this.sound_url = "resources/video/.m4a";
        //       break;
        //     }
        //     case "4": {
        //       this.sound_url = "resources/video/.m4a";
        //       break;
        //     }
        //     case "6": {
        //       this.sound_url = "resources/video/.m4a";
        //       break;
        //     }
        //     case "9": {
        //       this.sound_url = "resources/video/.m4a";
        //       break;
        //     }
        //     case "11": {
        //       this.sound_url = "resources/video/.m4a";
        //       break;
        //     }
        //     case "12": {
        //       this.sound_url = "resources/video/.m4a";
        //       break;
        //     }
        //     case "13": {
        //       this.sound_url = "resources/video/.m4a";
        //       break;
        //     }
        //     case "14": {
        //       this.sound_url = "resources/video/.m4a";
        //       break;
        //     }
        //     case null: {
        //       this.sound_url = "";
        //     }
        //   }

        // try {
        //   if (this.sound_url != "") {
        //     this.playSound(this.alarm_obj[0].DEventType);
        //   }
        // } catch (err) {}
      } else {
        this.sound_url = "";
      }
    },
  },
  beforeDestroy: function () {
    // this.socket.deleteListener(this);
    if (this.scheduleTimer != null) {
      clearTimeout(this.scheduleTimer);
    }
    EventBus.$off("demoEvent");
    EventBus.$off("eventTable");
  },
};
</script>

<style>
.dashboard_alarm_panel {
  height: 100%;
  width: 100%;
}
.div_alarm {
  width: 100%;
  height: 100%;
  display: inline-flex;
}
.alarm_icon {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
}
.alarm_icon .alarm_icon_text_area {
  background-color: #e64359;
  width: 1.3vw;
  height: 1.5vw;
  border-radius: 0.5vw;
  text-align: center;
  vertical-align: middle;
  margin: -16% 0% 0% -5%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 1%;
}
.alarm_title {
  font: normal normal normal 16px/24px Noto Sans KR;
  color: #e64359;
  opacity: 1;
  font-size: 0.9vw;
  margin-right: 1vw;
}
.alarm_event {
  font: normal normal normal 16px/24px Noto Sans KR;
  color: #c8c8c8;
  opacity: 1;
  font-size: 0.9vw;
  min-width: 20%;
  margin-left: 1vw;
  overflow: hidden;
}
.alarm_date {
  text-align: left;
  font: normal normal 300 14px/17px Lato;
  letter-spacing: 0px;
  color: #949494;
  opacity: 1;
  font-size: 0.9vw;
  margin-left: 1.3vw;
}
.alarm_button_title {
  text-align: left;
  font: normal normal normal 14px/17px Lato;
  letter-spacing: 0px;
  color: #e7e7e7;
  opacity: 1;
  font-size: 1rem;
}
.alarm_button {
  height: 13%;
  width: 15%;
  background-color: rgb(62 68 83);
  border-radius: 100px;
  opacity: 1;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 15% 1% 0px 0px;
}
.alarm_button2 {
  height: 13%;
  width: 40%;
  background-color: rgba(44, 44, 44, 1);
  border-radius: 5px;
  opacity: 1;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin: 5% 30% 0% 30%; */
  margin: 0 30% 0% 30%;
}
.alarm_main_title {
  height: 50%;
  width: 100%;
  font-size: 1rem;
  text-align: center;
  letter-spacing: 0;
  color: #f8f8fb;
  opacity: 1;
}
.alarm_main_title_down {
  height: 50%;
  width: 100%;
  font-size: 0.8rem;
  text-align: center;
  letter-spacing: 0;
  color: #f8f8fb;
  opacity: 1;
}
.iframebox {
  /* width: 100%;
  height: 100%; */
  width: 98%;
  height: 92%;
}
.alarm_info {
  display: flex;
  line-height: 1;
  font-size: 0.8rem;
}
.alarm_info img {
  width: 2.3vw;
  height: 1.2vw;
}
.detect {
  color: rgb(44, 206, 79);
  font-weight: 800;
}
.alarm .un {
  color: rgb(196, 97, 97);
}
.alarm {
  margin-bottom: 7%;
}
.content {
  width: auto;
  height: auto;
  word-break: break-all;
}
.font-custom {
  font-size: 0.8rem !important;
  font-weight: 400 !important;
}
.dashboard_table_center thead th {
  font-size: 0.8rem; /* 원하는 크기로 설정하세요 */
  height: 30px;
}
.dashboard_table_center tbody td {
  font-size: 0.8rem; /* 원하는 크기로 설정하세요 */
  height: 30px;
}
.popup-title {
  color: white;
  font-weight: bold;
  /* font-size: 14pt;
  margin-left: 5px; */
  font-weight: 600;
  flex: 1 1 0%;
}
</style>
